import useAxios from "axios-hooks";
import { getDaysInMonth, setMonth } from "date-fns";
import { useCallback, useEffect } from "react";
import { createGlobalState } from "react-hooks-global-state";
import { useSelector } from "react-redux";
import FuelSource from "../../Models/FuelSource";
import { Site } from "../../Models/Site";
import { State } from "../../State";
import FuelUse from "../../Models/FuelUse";

const initialState = {
  isLoading: false,
  sites: [] as any[],
  carbonEmissions: [] as any[],
  energyWaste: [] as any[],
  waste: [] as any[],
  fuelSources: [] as FuelSource[],
  fuelUses: [] as FuelUse[],
};
const { useGlobalState } = createGlobalState(initialState);

const PortfolioState = () => {
  const [isLoading, setLoading] = useGlobalState("isLoading");
  const [sites, setSites] = useGlobalState("sites");
  const [carbonEmissions, setCarbonEmissions] =
    useGlobalState("carbonEmissions");
  const [detailedSites, setDetailedSites] = useGlobalState("energyWaste");
  const [energyWaste, setEnergyWaste] = useGlobalState("energyWaste");
  const [waste, setWaste] = useGlobalState("waste");
  const [fuelSources, setFuelSources] = useGlobalState("fuelSources");
  const [fuelUses, setFuelUses] = useGlobalState("fuelUses");
  const year = useSelector((state: State) => state.year);
  const month = useSelector((state: State) => state.month);
  const fuelSource = useSelector((state: State) => state.fuelSource);
  const site = useSelector((state: State) => state.site);

  const [, getEnergyWaste] = useAxios<{
    energyTargets: any[];
  }>(
    {
      url: "/dashboard/energyWaste",
    },
    { manual: true }
  );

  const [getSitesReq, getSites] = useAxios<Site[]>(
    {
      url: "/business/sites",
    },
    { manual: true }
  );

  const [, getUses] = useAxios<FuelUse[]>(
    {
      url: "/utility/fuelUse",
    },
    { manual: true }
  );

  const [getPortfolioDataReq, getPortfolioData] = useAxios<{
    carbonEmissions: any[];
    energyTargets: any[];
    sites: any[];
  }>(
    {
      url: "/dashboard/portfolio",
    },
    { manual: true }
  );

  const [fuelSourceReq, getFuelSource] = useAxios<FuelSource[]>(
    {
      url: "/utility/fuelSources",
    },
    { manual: true }
  );

  const isFetching =
    getPortfolioDataReq.loading || fuelSourceReq.loading || getSitesReq.loading;

  useEffect(() => {
    setLoading(isFetching);
  }, [getPortfolioDataReq.loading]);

  useEffect(() => {
    setLoading(isFetching);
    if (fuelSourceReq.data && fuelSourceReq.data.length !== 0) {
      setFuelSources(fuelSourceReq.data);
    }
  }, [fuelSourceReq.data, fuelSourceReq.loading]);

  useEffect(() => {
    setLoading(isFetching);
    if (getSitesReq.data && getSitesReq.data.length !== 0) {
      setSites(getSitesReq.data);
    }
  }, [getSitesReq.data, getSitesReq.loading]);

  const fetchUses = useCallback(() => {
    getUses().then(({ data }) => {
      setFuelUses(data);
    });
  }, []);

  const fetchSites = () => {
    return getSites().then(({ data }) => {
      if (data.length !== 0) {
        console.log("Site data", data);
        setSites(data);
      }
    });
  };

  const fetchPortfolioData = () => {
    if (!year) return;
    if (!fuelSource) return;
    if (!month) return;
    return getPortfolioData({
      url: `/dashboard/portfolio?year=${year.value}&fuelSourceId=${fuelSource.value}&month=${month.value}`,
    }).then(({ data }) => {
      setCarbonEmissions(data.carbonEmissions);
      setEnergyWaste(data.energyTargets);
      setDetailedSites(data.sites);
    });
  };

  const selectedMonthDaysAmount = getDaysInMonth(
    setMonth(new Date(), month.value)
  );

  const getWaste = useCallback(() => {
    const params: Record<string, string | number> = {
      year: year.label,
    };
    if (fuelSource?.value) {
      params.fuelSource = fuelSource.value;
    }
    if (site?.id && site?.id !== -1) {
      params.siteId = site.id;
    }
    return getEnergyWaste({
      params,
    }).then(({ data }: any) => {
      setWaste(data?.waste);
    });
  }, [year, fuelSource, site]);

  return {
    fetchSites,
    fetchPortfolioData,
    getFuelSource,
    fetchUses,
    waste,
    detailedSites,
    fuelSources,
    fuelUses,
    energyWaste,
    isLoading: isLoading,
    sites,
    carbonEmissions,
    selectedMonthDaysAmount,
    getWaste,
  };
};

export default PortfolioState;
