import { Site } from "../../Models/Site";
import styles from "../Tables/styles.module.css";
import PortfolioState from "./PortfolioState";
import { useSelector } from "react-redux";
import { State } from "../../State";

interface Props {
  expand: boolean;
  data: (Site & { uses: number[]; fuels: number[] })[];
}
export default function Table({ expand, data }: Props) {
  const month = useSelector((state: State) => state.month);
  const fuelSource = useSelector((state: State) => state.fuelSource);

  const state = PortfolioState();
  return (
    <table className={`${styles.tableReport} ${expand && styles.expand}`}>
      <thead>
        <tr>
          <th>No.</th>
          <th>Business name</th>
          <th>Property type</th>
          <th>Address</th>
          <th>Postal code</th>
          <th>Town</th>
          <th>Population</th>
          <th>Size (m2)</th>
          <th>Fuel</th>
          <th>Uses</th>
          <th>Trend</th>
        </tr>
      </thead>
      <tbody>
        {data.map((i) => {
          const [waste] = state.waste
            .flat()
            .filter((w) => w.siteId === i.id)
            .filter((w) =>
              fuelSource?.value === -1
                ? true
                : w.fuelSourceId === fuelSource?.value
            )
            .filter(
              (w) => Number.parseInt(w.date.split("-")[1]) === month?.value + 1
            );
          const down = waste?.increasedPercentage < 0;
          return (
            <tr key={i.name}>
              <td>{i.id}</td>
              <td>{i.name}</td>
              <td>{i.type}</td>
              <td>{i.address}</td>
              <td>{i.postCode}</td>
              <td>{i.town}</td>
              <td>{i.population}</td>
              <td>{i.size}</td>
              <td>
                {state.fuelSources
                  .filter((f) => (i.fuels ?? []).includes(f.id))
                  .map((f) => f.source)
                  .join(", ")}
              </td>
              <td>
                {state.fuelUses
                  .filter((f) => (i.uses ?? []).includes(f.id))
                  .map((f) => f.use)
                  .join(", ")}
              </td>
              <td>
                <i
                  className={`fas fa-long-arrow-alt-${down ? "down" : "up"} fa-3x text-${down ? "danger" : "success"}`}
                ></i>
              </td>
            </tr>
          );
        })}
      </tbody>
    </table>
  );
}
