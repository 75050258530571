import Card from "../Card";
import Layout from "../Layout";
import ReportSide from "./ReportsSidebar";
import style from "../analytics.module.css";
import { useCallback, useEffect, useState } from "react";
import {
  ConsumptionDataItem,
  CarbonDataItem,
  TargetConsumption,
} from "../Charts/Bars/Stacked_GroupedSigns";
import { useSelector } from "react-redux";
import { State } from "../../State";
import PieChart from "../Charts/Pies/Full";
import Table from "./ReportTargetTable";
import Overview from "../Tables/ReportsTable";
import ReportState from "./ReportState";
import LineChart from "../Charts/Lines/LineChart";
import { formatISO } from "date-fns";

const MTColorPallet = {
  energyConsumption: "#E97871",
  energyTarget: "#4989C6",
  carbonConsumption: "#94C11F",
  carbonTarget: "#F5B363",
};

const arrayIsNotEmpty = (arr: unknown[]) => arr.length !== 0;

const ChangeDisplay = (
  value: number,
  expand: boolean,
  selectedIndex: number,
  consumption: ConsumptionDataItem[][],
  emissions: CarbonDataItem[][],
  targetConsumptions: TargetConsumption[][]
) => {
  switch (value) {
    case 0:
      return (
        <LineChart
          loading={false}
          selectedIndex={0}
          dataSet={[
            ...consumption.filter(arrayIsNotEmpty).map((t) =>
              t.map((i) => {
                return {
                  date: i.date,
                  value: i.consumption,
                  cost: i.consumption,
                  siteName: i.siteName,
                  fuelSourceName: i.fuelSourceName,
                  colorCode: MTColorPallet.energyConsumption,
                };
              })
            ),
            ...targetConsumptions.filter(arrayIsNotEmpty).map((t) =>
              t.map((i) => {
                return {
                  date: i.date,
                  value: i.projectedEnergy,
                  cost: i.projectedEnergy,
                  siteName: i.siteName,
                  fuelSourceName: i.fuelSourceName,
                  colorCode: MTColorPallet.energyTarget,
                };
              })
            ),
            ...emissions.filter(arrayIsNotEmpty).map((t) =>
              t.map((i) => {
                return {
                  date: i.date,
                  value: i.carbonEmission,
                  cost: i.carbonEmission,
                  siteName: i.siteName,
                  fuelSourceName: i.fuelSourceName,
                  colorCode: MTColorPallet.carbonConsumption,
                };
              })
            ),
            ...emissions.map((t) =>
              t.map((i) => {
                return {
                  date: i.date,
                  value: i.carbonTarget,
                  cost: i.carbonTarget,
                  siteName: i.siteName,
                  fuelSourceName: i.fuelSourceName,
                  colorCode: MTColorPallet.carbonTarget,
                };
              })
            ),
          ]}
        />
      );
    case 1:
      return (
        <Table
          expand={expand}
          selectedIndex={selectedIndex}
          carbonEmissions={emissions.flat()}
          consumptions={consumption.flat()}
        />
      );
    case 2:
      const currentMonthConsumption = consumption
        .flat()
        .find((c) => c.date.getMonth() === selectedIndex);
      const currentMonthCarbonEmission = emissions
        .flat()
        .find((c) => c.date.getMonth() === selectedIndex);
      return (
        <div className="d-flex h-100 mx-5 justify-content-between">
          <div
            className="h-100"
            style={{
              width: "40%",
            }}
          >
            <PieChart
              data={[
                {
                  name: "consumption",
                  value: currentMonthConsumption?.consumption || 0,
                },
                {
                  name: "projectedEnergy",
                  value: currentMonthConsumption?.projectedEnergy || 0,
                },
              ]}
              colors={["#E97871", "#4989C6"]}
              thick={true}
            />
          </div>
          <div
            className="h-100"
            style={{
              width: "40%",
            }}
          >
            <PieChart
              data={[
                {
                  name: "consumption",
                  value: currentMonthCarbonEmission?.carbonEmission || 0,
                },
                {
                  name: "projectedEnergy",
                  value: currentMonthCarbonEmission?.carbonTarget || 0,
                },
              ]}
              colors={["#94C11F", "#F5B363"]}
              thick={true}
            />
          </div>
        </div>
      );
    default:
      return <div></div>;
  }
};

export default function Reports() {
  const [fullFirst, setFullFirst] = useState(false);
  const [fullSecond, setFullSecond] = useState(false);
  const [fullThird, setFullThird] = useState(false);
  const display = useSelector((state: State) => state.display);

  const month = useSelector((state: State) => state.month);
  const year = useSelector((state: State) => state.year);
  const site = useSelector((state: State) => state.site);
  const fuelSource = useSelector((state: State) => state.fuelSource);

  const state = ReportState();
  useEffect(() => {
    state.getFuelSource();
    state.getUses();
    state.getSites();
  }, []);

  useEffect(() => {
    if (state.sites.length !== 0) {
      state.fetchReports();
    }
  }, [site, year, fuelSource]);

  const filterByFuelSource = useCallback<any>(
    (arr: ConsumptionDataItem[] | CarbonDataItem[]) => {
      return arr
        .filter((i) =>
          fuelSource?.value === -1 ? true : i.fuelSourceId === fuelSource?.value
        )
        .filter((i) => (site?.id === -1 ? true : i.siteId === site?.id));
    },
    [fuelSource, site]
  );

  return (
    <Layout rightSideBar={<ReportSide />}>
      <div className={`flex-fill d-flex flex-column ${style.reports}`}>
        <h4 className={`${style.sectionHeader} mt-4 text-center`}>Reports</h4>
        <div className="flex-fill mx-3 mb-4 position-relative">
          <div className={`${style.halfFirst} ${fullFirst && style.full} p-3`}>
            <Card
              bodyClassName={"overflow-scroll"}
              onClick={() => setFullFirst(!fullFirst)}
              title="Overview"
              expanded={fullFirst}
            >
              <Overview reports={state.reports} expand={fullFirst} />
            </Card>
          </div>
          <div
            className={`${style.quaterThird} ${fullSecond && style.full} p-3`}
          >
            <Card
              onClick={() => setFullSecond(!fullSecond)}
              title="M & T"
              expanded={fullSecond}
              bottom={
                display.value !== 1 && (
                  <div className="d-flex align-items-center mt-2">
                    <span
                      className={style.legendCircle}
                      style={{
                        backgroundColor: MTColorPallet.energyConsumption,
                      }}
                    />
                    <span className={`${style.legend}`}>
                      {fuelSource?.label && fuelSource?.value !== 0
                        ? fuelSource?.label
                        : ""}{" "}
                      Energy Consumption
                    </span>
                    <span
                      className={`${style.legendCircle} ms-3`}
                      style={{
                        backgroundColor: MTColorPallet.energyTarget,
                      }}
                    />
                    <span className={`${style.legend}`}>
                      {fuelSource?.label && fuelSource?.value !== 0
                        ? fuelSource?.label
                        : ""}{" "}
                      Energy Target
                    </span>
                    <span
                      className={`${style.legendCircle} ms-auto`}
                      style={{
                        backgroundColor: MTColorPallet.carbonConsumption,
                      }}
                    />
                    <span className={`${style.legend}`}>
                      {fuelSource?.label && fuelSource?.value !== 0
                        ? fuelSource?.label
                        : ""}{" "}
                      Carbon Consumption
                    </span>
                    <span
                      className={`${style.legendCircle} ms-3`}
                      style={{
                        backgroundColor: MTColorPallet.carbonTarget,
                      }}
                    />
                    <span className={`${style.legend}`}>
                      {fuelSource?.label && fuelSource?.value !== 0
                        ? fuelSource?.label
                        : ""}{" "}
                      Carbon Target
                    </span>
                  </div>
                )
              }
            >
              {ChangeDisplay(
                display.value,
                fullSecond,
                month.value,
                state.consumptions
                  .map((a) =>
                    a.filter(
                      (i: any) =>
                        formatISO(i.date, { format: "extended" }).split(
                          "-"
                        )[0] === year.value.toString()
                    )
                  )
                  .filter((i) => i.length !== 0)
                  .map(filterByFuelSource),
                state.carbonEmissions
                  .map((a) =>
                    a.filter(
                      (i: any) =>
                        formatISO(i.date, { format: "extended" }).split(
                          "-"
                        )[0] === year.value.toString()
                    )
                  )
                  .filter((i) => i.length !== 0)
                  .map(filterByFuelSource),
                state.targetConsumptions
                  .map((a) =>
                    a.filter(
                      (i: any) =>
                        formatISO(i.date, { format: "extended" }).split(
                          "-"
                        )[0] === year.value.toString()
                    )
                  )
                  .filter((i) => i.length !== 0)
                  .map(filterByFuelSource)
              )}
            </Card>
          </div>
          <div
            className={`${style.quaterFourth} ${fullThird && style.full} p-3`}
          >
            <Card
              onClick={() => setFullThird(!fullThird)}
              title="Number Of Reports"
              expanded={fullThird}
              bottom={
                <div className="d-flex align-items-center mt-2">
                  <span
                    className={style.legendCircle}
                    style={{
                      backgroundColor: "#4989C6",
                    }}
                  />
                  <span className={`${style.legend} me-3`}>
                    Reports this year
                  </span>
                </div>
              }
              leftTop={
                <div
                  className={`rounded-circle text-center p-2 d-flex flex-column`}
                  style={{
                    backgroundColor: "#E7E7E8",
                  }}
                >
                  <span className={`${style.sm17lg33} fw-bold`}>
                    {year.value}
                  </span>
                  <small className={style.sm7lg12}>year</small>
                </div>
              }
              rightTop={
                <>
                  <span
                    style={{
                      fontSize: "12px",
                      color: "#94C11F",
                    }}
                  >
                    Reports this year {state.totalYearReports}/12
                  </span>
                  <br />
                  <span
                    style={{
                      fontSize: "12px",
                      color: "#E97871",
                    }}
                  >
                    Missing Report: {12 - state.totalYearReports}
                  </span>
                </>
              }
            >
              <PieChart
                colors={["#4989C6", "#E8E8E8"]}
                text={`${state.totalYearReports} reports`}
                data={[
                  { name: "Group A", value: state.totalYearReports },
                  { name: "Group B", value: 12 - state.totalYearReports },
                ]}
              />
            </Card>
          </div>
        </div>
      </div>
    </Layout>
  );
}
