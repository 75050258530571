import * as d3 from "d3";
import { useEffect, useRef, useState } from "react";
import { months } from "../../../Helper/Year";
import ChartDates, { ChartYears } from "../../../Helper/DateFormat";
import { ResponsiveContainer } from "recharts";
const randomColor = require("randomcolor"); // import the script

export type LineChartDataSet = {
  date: Date;
  value: number;
  cost: number;
  siteName: string;
  fuelSourceName: string;
};
const Chart = (p: {
  dataSet?: LineChartDataSet[][];
  showNegativeValues?: boolean;
  selectedIndex: number;
  isCallingFromCarbonFootPrint?: boolean;
  myRef: any;
  expand?: boolean;
  width: number;
}) => {
  const svgRef = useRef(null);
  const data = p.dataSet || [];

  useEffect(() => {
    if (data.length === 0) {
      return;
    }
    var margin = { top: 20, right: 20, bottom: 50, left: 70 },
      width = p.width || p.myRef.current?.offsetWidth,
      height = p.myRef.current?.offsetHeight;

    var svg = d3
      .select(svgRef.current)
      .attr("width", width)
      .attr("id", "consumption")
      .attr("height", height)
      .attr("transform", "translate(0, 0)");

    const everything = svg.selectAll("*");
    everything.remove();

    // Declare the positional encodings.
    var x = d3.scaleTime().range([40, width - 31]);
    var y = d3.scaleLinear().range([height - 20, 0]);
    const dateDomain = d3.extent(data.flat(), (d) => d.date) as [Date, Date];
    x.domain(dateDomain);
    let maxValue = 0;
    for (let i = 0; i < data.length; i++) {
      const value =
        data &&
        (data[i].reduce((acc: any, obj: any) => {
          return (acc = acc > obj.value ? acc : obj.value);
        }, []) as any);
      maxValue = maxValue > value ? maxValue : value;
    }
    if (p.isCallingFromCarbonFootPrint) {
      y.domain([0, maxValue + 1000]);
    } else {
      y.domain([0, maxValue + 100]);
    }

    let tips: d3.Selection<HTMLDivElement, unknown, HTMLElement, any>[] = [];

    // global function
    const line = (d3 as any)
      .line()
      .curve(d3.curveCatmullRom)
      .x((d: any) => x(d.date))
      .y((d: any) => y(d.value));

    svg
      .append("defs")
      .append("linearGradient")
      .attr("id", "myGradient")
      .attr("x1", "0%")
      .attr("y1", "0%")
      .attr("x2", "0%")
      .attr("y2", "100%")
      .selectAll("stop")
      .data([
        { offset: "0%", color: "#ffffff" },
        { offset: "50%", color: "#ccc" },
      ])
      .enter()
      .append("stop")
      .attr("offset", (d) => d.offset)
      .attr("stop-color", (d) => d.color);

    svg
      .append("g")
      .attr("transform", `translate(0,${height - 20})`)
      .call(
        d3
          .axisBottom(x)
          .ticks(d3.timeMonth)
          .tickFormat((d) => {
            return d instanceof Date ? ChartYears(d) : "";
          })
      )
      .call((g) => g.select(".domain").remove())
      .call((g) =>
        g
          .selectAll("text")
          .style("text-anchor", "middle")
          .style("font-weight", function (d: any) {
            if (months[p.selectedIndex].label === months[d.getMonth()].label) {
              return "bold";
            }
            return "normal";
          })
          .style("color", function (d: any) {
            if (months[p.selectedIndex].label === months[d.getMonth()].label) {
              return "black";
            }
            return "#8e8e8e";
          })
      )
      .call((g) =>
        g
          .select("rect")
          .attr("x", function (d, index) {
            return 50;
          })
          .attr("y", function (d) {
            return 0;
          })
          .attr("height", function (d) {
            return 450;
          })
          .attr("width", function (d, index) {
            return 36;
          })
          .attr("fill", function (d, index) {
            return "url(#myGradient)";
          })
      )
      .call((g) =>
        g
          .selectAll("line")
          .attr("y1", 5)
          .attr("y2", -height + 25)
          .attr("stroke-opacity", 0.1)
      );

    svg
      .append("g")
      .attr("transform", `translate(40, 5)`)
      .call(d3.axisLeft(y))
      .call((g) => g.select(".domain").remove())
      .call((g) =>
        g
          .selectAll("line")
          .attr("x2", width - 70)
          .attr("stroke-opacity", 0.1)
      );

    for (let i = 0; i < data.length; i++) {
      svg
        .append("path")
        .attr("transform", "translate(0,5)")
        .datum(data[i])
        .attr("fill", "none")
        .attr("stroke", i % 2 == 0 ? "#4989C6" : "#94C11F ")
        .attr("stroke-width", 2.5)
        .attr("stroke-linejoin", "round")
        .attr("stroke-linecap", "round")
        .attr("d", line as any);

      svg
        .append("g")
        .attr("transform", "translate(0,5)")
        .attr("fill", "white")
        .attr("stroke", i % 2 == 0 ? "#4989C6" : "#94C11F ")
        .attr("stroke-width", 2)
        .selectAll("circle")
        .data(data[i])
        .join("circle")
        .attr("cx", (d: any) => x(d.date))
        .attr("cy", (d: any) => y(d.value))
        .attr("r", 3)
        .on("mouseover", function (event, d) {
          const t = d3
            .select("body")
            .append("div")
            .attr("class", "tooltip")
            .style("opacity", 0)
            .style("position", "absolute")
            .style("pointer-events", "none")
            // .style("background", "rgba(0,0,0,0.8)")
            .style("z-index", 10)
            .style("padding", "8px")
            .style("font-size", "11px")
            .style("border-radius", "10px");
          t.style("opacity", 1);
          t.style("color", "white");
          t.style("background", i % 2 == 0 ? "#4989C6" : "#94C11F ");
          const body = `
                <div><b>value:</b> ${d.value}</div>
                  <div><b>Site:</b> ${d?.siteName}</div>
                  <div><b>FuelSource:</b> ${d?.fuelSourceName}</div>
                `;
          t.html(
            `<h6>${ChartYears(new Date(d.date) || new Date())}</h6>${body}`
          );
          tips.push(t);
        })
        .on("mousemove", function (event, d) {
          tips.forEach((t) => {
            t.style("left", `${event.pageX}px`).style(
              "top",
              `${event.pageY}px`
            );
          });
        })
        .on("mouseleave", function () {
          tips.forEach((t) => t.remove());
        });
    }
  }, [p.dataSet, svgRef.current, p.myRef.current, p.expand, p.width]);

  return <svg ref={svgRef} />;
};

interface Props {
  loading: boolean;
  expand?: boolean;
  selectedIndex: number;
  dataSet?: LineChartDataSet[][];
  showNegativeValues?: boolean;
  isCallingFromCarbonFootPrint?: boolean;
}

export default function LineChart({
  expand,
  selectedIndex,
  dataSet = [],
  loading,
  showNegativeValues,
  isCallingFromCarbonFootPrint,
}: Props) {
  const myRef = useRef<any>(null);
  const [width, setWidth] = useState(0);

  console.log("width => ", width);

  useEffect(() => {

    // Add ResizeObserver to track size changes
    let timeoutId: NodeJS.Timeout;
    
    try {
      const resizeObserver = new ResizeObserver((entries) => {
        // Clear any existing timeout
        clearTimeout(timeoutId);
        
        // Set new timeout to update width
        timeoutId = setTimeout(() => {
          for (const entry of entries) {
            setWidth(entry.contentRect.width);
          }
        }, 1); // 250ms delay
      });

      if (myRef.current) {
        resizeObserver.observe(myRef.current);
      }

      return () => {
        clearTimeout(timeoutId);
        resizeObserver.disconnect();
      };
    } catch (error) {
      console.error("Error setting up ResizeObserver:", error);
    }
  }, [myRef.current]); // Empty dependency array since we're using ResizeObserver


  useEffect(() => {
    if (myRef.current) {
      console.log("final Width:", myRef.current.offsetWidth);
    } else {
      console.error("Ref not attached to the component");
    }
  }, [myRef, expand]);

  if (loading) {
    return (
      <div className="d-flex justify-content-center align-items-center h-100">
        <div className="spinner-border" role="status">
          <span className="visually-hidden">Loading...</span>
        </div>
      </div>
    );
  } else if (
    dataSet.length === 0 ||
    (dataSet.length > 0 && dataSet[0].length === 0)
  ) {
    return (
      <div className="d-flex justify-content-center align-items-center h-100">
        <h3>No data</h3>
      </div>
    );
  } else {
    return (
      <div
        style={{ position: "absolute", height: "100%", width: "100%" }}
        ref={myRef}
      >
        <Chart
          showNegativeValues={showNegativeValues}
          dataSet={dataSet}
          selectedIndex={selectedIndex}
          isCallingFromCarbonFootPrint={isCallingFromCarbonFootPrint}
          myRef={myRef}
          expand={expand}
          width={width}
        />
      </div>
    );
  }
}
