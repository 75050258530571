import { useEffect, useState } from "react";
import Layout from "../Layout";
import PortfolioSide from "./PortfolioSideMenu";
import Card from "../Card";
import style from "../analytics.module.css";
import PieChart from "../Charts/Pies/Full";
import Table from "./PortfolioTable";
import BarChart from "./PortfolioBarChart";
import LineChart from "./PortfolioLineChart";
import OverlappingInfo from "../OverlappingInfo";
import PortfolioState from "./PortfolioState";
import { useSelector } from "react-redux";
import { State } from "../../State";
import TargetConsumptionPanel from "../UtilityLeague/TargetConsumptionPanel";
import SideMenuState from "../UtilityLeague/LeageState";
import DoubleBarChart from "../Charts/Bars/StackedSigns";
import IncreasedPercentage from "../UtilityLeague/IncreasedPercentage";

export default function Portfolio() {
  const [fullFirst, setFullFirst] = useState(false);
  const [fullSecond, setFullSecond] = useState(false);
  const [fullThird, setFullThird] = useState(false);
  const [fullFourth, setFullFourth] = useState(false);

  const year = useSelector((state: State) => state.year);
  const month = useSelector((state: State) => state.month);
  const fuelSource = useSelector((state: State) => state.fuelSource);
  const site = useSelector((state: State) => state.site);
  const state = PortfolioState();
  const [DataArray, setDataArray] = useState<any[]>();
  const pageState = SideMenuState();

  console.log("carbonFootPrint", pageState.carbonFootPrint);
  console.log("month value", month.value);

  useEffect(() => {
    const fetchData = async () => {
      if (year && site) {
        const res: any = await pageState.triggerStatisticsSearch({
          year,
          site,
        });
        console.log("Result", res);
        if (res) {
          setDataArray(res);
          pageState.setConsumptions(res.consumptions || []);
        }

        await pageState.fetchCarbonFootPrint({
          year,
          site,
        });

        await pageState.fetchCarbonFootPrintPreviousYear({
          year,
          site,
        });
      }
    };
    pageState.triggerStatisticsSearchPreviousYear({
      year,
      site,
    });
    fetchData();
  }, [year, site]);

  useEffect(() => {
    state.fetchSites();
  }, []);

  useEffect(() => {
    state.fetchPortfolioData();
    state.getWaste();
  }, [year, month, fuelSource, site]);

  return (
    <Layout rightSideBar={<PortfolioSide />}>
      <div className="flex-fill d-flex flex-column">
        <h4 className={`${style.sectionHeader} mt-4 text-center`}>Portfolio</h4>
        <div className="flex-fill mx-3 mb-4 position-relative">
          <div
            className={`${style.quaterFirst} ${fullFirst && style.full} p-3`}
          >
            <Card
              onClick={() => setFullFirst(!fullFirst)}
              title="Number of Sites"
              expanded={fullFirst}
              bottom={
                <div className="d-flex align-items-center mt-2">
                  <span
                    className={style.legendCircle}
                    style={{
                      backgroundColor: "#4989C6",
                    }}
                  />
                  <span className={style.legend}>Number of sites</span>
                </div>
              }
            >
              <PieChart
                colors={["#4989C6", "#E8E8E8"]}
                text={`${state.sites.length} sites`}
                data={[{ name: "aa", value: 800 }]}
              />
            </Card>
          </div>
          <div
            className={`${style.quaterSecond} ${fullSecond && style.full} p-3`}
          >
            <Card
              onClick={() => setFullSecond(!fullSecond)}
              title={<div className="d-flex">Table of Sites</div>}
              expanded={fullSecond}
            >
              <Table data={state.detailedSites} expand={fullSecond} />
            </Card>
          </div>
          <div>
            {/* <Card
              onClick={() => setFullThird(!fullThird)}
              title="Energy M & T (kWh)"
              expanded={fullThird}
              top={
                <div className="d-flex align-items-center">
                  <div
                    className="rounded-circle text-center p-2 d-flex flex-column ms-auto"
                    style={{
                      backgroundColor: "#E7E7E8",
                    }}
                  >
                    <span className={`${style.sm17lg33} fw-bold`}>
                      {state.selectedMonthDaysAmount}
                    </span>
                    <small className={style.sm7lg12}>days</small>
                  </div>
                  <small
                    className={`${style.sm12lg17} mx-2`}
                    style={{
                      fontWeight: 600,
                      color: "#94C11F",
                    }}
                  >
                    <i className="fas fa-arrow-up"></i> 13.8%
                  </small>
                  <OverlappingInfo
                    text1="Target Monthly usage"
                    value1="20 kWh"
                    color1="#94C11F"
                    text2="Actual Monthly usage"
                    value2="180 kWh"
                    color2="#4989C6"
                  />
                </div>
              }
            >
              <BarChart
                data={state.energyWaste}
                expand={fullThird}
              />
            </Card> */}
            <TargetConsumptionPanel />
          </div>
          <div
            className={`${style.quaterFourth} ${fullFourth && style.full} p-3`}
          >
            <Card
              onClick={() => setFullFourth(!fullFourth)}
              title="Carbon Baseline (kg)"
              expanded={fullFourth}
              top={
                <div className="d-flex align-items-center">
                  <div
                    className="rounded-circle text-center p-2 d-flex flex-column ms-auto"
                    style={{
                      backgroundColor: "#E7E7E8",
                    }}
                  >
                    <span className={`${style.sm17lg33} fw-bold`}>
                      {pageState.selectedMonthDaysAmount}
                    </span>
                    <small className={style.sm7lg12}>days</small>
                  </div>
                  <IncreasedPercentage
                    className={`${style.sm12lg17} mx-2`}
                    percentage={
                      pageState.getFootPrintForMonth(month.value)
                        ?.carbonEmission === undefined ||
                      pageState.getFootPrintForMonth(month.value)
                        ?.carbonTarget === undefined
                        ? undefined
                        : Number(
                            pageState.calculatePercentageChange(
                              pageState.getFootPrintForMonth(month.value)
                                ?.carbonEmission,
                              pageState.getFootPrintForMonth(month.value)
                                ?.carbonTarget
                            )
                          )
                    }
                  />
                  <OverlappingInfo
                    text2="Actual Monthly Emissions"
                    value2={`
                      ${
                        pageState.getFootPrintForMonth(month.value)
                          ?.carbonEmission || 0
                      }  Kg
                       
                    `}
                    color1="#94C11F"
                    text1="Target Monthly Emissions"
                    value1={`
                      ${
                        pageState.getFootPrintForMonth(month.value)
                          ?.carbonTarget || 0
                      } Kg
                    `}
                    color2="#4989C6"
                  />
                </div>
              }
              bottom={
                <div className="d-flex align-items-center mt-2">
                  <span
                    className={style.legendCircle}
                    style={{
                      backgroundColor: "#4989C6",
                    }}
                  />
                  <span className={`${style.legend} me-3`}>
                    Actual {fuelSource?.label} Consumption {year.value}
                  </span>
                  <span
                    className={style.legendCircle}
                    style={{
                      backgroundColor: "#94C11F",
                    }}
                  />
                  <span className={style.legend}>
                    Target {fuelSource?.label} Consumption {year.value}
                  </span>
                </div>
              }
            >
              <DoubleBarChart
                isEmission={true}
                expand={fullSecond}
                selectedIndex={month.value}
                energyTargetsRecords={pageState.carbonFootPrint
                  .map((r) => {
                    return r.map((i: any) => {
                      return {
                        ...i,
                        consumption: i.carbonEmission,
                        targetEnergy: i.carbonTarget,
                      };
                    });
                  })
                  .sort((a: any, b: any) => {
                    if (a.length === 0 || b.length === 0) return 1;

                    return a[0].date.getTime() - b[0].date.getTime();
                  })}
                loading={false}
              />
            </Card>
          </div>
        </div>
      </div>
    </Layout>
  );
}
