import { useEffect, useRef, useState } from "react";
import * as d3 from "d3";
import { ChartYears } from "../../../Helper/DateFormat";
import { months } from "../../../Helper/Year";
import { fromUnixTime, getUnixTime } from "date-fns";
const Color = require("color");

const BarColorPallet = {
  left: "#4989c6",
  right: "#94c11f",
};

type LineChartDataSet = {
  date: Date;
  consumption: number;
  targetEnergy: number;
  fuelSourceName: string;
  siteName: string;
};

type LineChartSubset = Omit<
  LineChartDataSet,
  "consumption" | "targetEnergy"
> & {
  value: number;
};

const Chart = (p: {
  dataSet?: LineChartSubset[][];
  selectedIndex: number;
  direction: "left" | "right";
  myRef: any;
  expand?: boolean;
  isEmission?: boolean;
}) => {
  const svgRef = useRef(null);
  const data = p.dataSet || [];

  useEffect(() => {
    if (data.length === 0) {
      return;
    }
    const fuelSources = Array.from(
      new Set(data.flat().map((r) => r.fuelSourceName))
    );
    const datesNames = Array.from(
      new Set(data.flat().map((r) => ChartYears(r.date))).values()
    );

    var svg: d3.Selection<SVGGElement, unknown, null, undefined>,
      tip: d3.Selection<HTMLDivElement, unknown, HTMLElement, any>;

    tip = d3
      .select("body")
      .append("div")
      .attr("class", "tooltip")
      .style("opacity", 0)
      .style("position", "absolute")
      .style("pointer-events", "none")
      .style("background", "rgba(0,0,0,0.8)")
      .style("z-index", 10)
      .style("padding", "8px")
      .style("font-size", "11px")
      .style("border-radius", "10px");

    const width =
      (p.myRef.current?.offsetWidth - (p.direction === "right" ? 140 : 10)) / 2;
    const height = p.myRef.current?.offsetHeight;
    // append the svg object to the body of the page
    svg = d3
      .select(svgRef.current)
      .attr("width", width)
      .attr("height", height)
      .append("g")
      .attr(
        "transform",
        `translate(${p.direction === "left" ? "50" : "0"}, 0)`
      );

    const xAxis = d3
      .scaleLinear()
      .domain([0, 1000])
      .range(p.direction === "right" ? [width, 0] : [0, width]);
    svg
      .append("g")
      .attr("transform", `translate(0, ${height - 30})`)
      .call(d3.axisBottom(xAxis).tickValues([0, 250, 500, 750, 1000]));

    const yAxis = d3
      .scaleBand<string>()
      .domain(datesNames)
      .range([0, height - 24]);
    svg
      .append("g")
      .call(d3.axisLeft(yAxis).ticks(d3.timeMonth))
      .attr("stroke-width", 0)
      .attr("transform", `translate(0, -5)`)
      .selectAll("text")
      .style("font-weight", function (d: any) {
        if (months[p.selectedIndex].label.includes(d.split(",")[0])) {
          return "bold";
        }
        return "normal";
      })
      .style("color", function (d: any) {
        if (months[p.selectedIndex].label.includes(d.split(",")[0])) {
          return "black";
        }
        return "#8e8e8e";
      })
      .style("font-size", 10);

    // color palette = one color per subgroup
    const color = d3
      .scaleOrdinal()
      .domain(fuelSources)
      .range(
        fuelSources.map((_, idx) =>
          Color(BarColorPallet[p.direction])
            .blacken(Number.parseFloat(`0.${idx * 4}`))
            .hex()
        )
      );

    //stack the data? --> stack per subgroup
    const stackedData = d3.stack().keys(fuelSources)(
      data.map((row) => {
        return row.reduce<Record<string, any>>((record, next) => {
          const data: Record<string, any> = { ...record };
          data.date = getUnixTime(next.date);
          data.siteName = next.siteName;
          data.fuelSources = data.fuelSources
            ? data.fuelSources.concat([next.fuelSourceName])
            : [next.fuelSourceName];
          data[next.fuelSourceName] = next.value;
          return data;
        }, {});
      })
    );

    // Add transition duration constant
    const TRANSITION_DURATION = 750;

    // Show the bars with transition
    svg
      .append("g")
      .selectAll("g")
      .data(stackedData)
      .join("g")
      .attr("fill", (d) => color(d.key) as string)
      .selectAll("rect")
      .data((d) => d)
      .join("rect")
      .attr("y", (d) => yAxis(ChartYears(fromUnixTime(d.data.date))) as any)
      .attr("x", (d) => (p.direction === "right" ? xAxis(d[1]) : 0))
      .attr("width", (d) =>
        p.direction === "right"
          ? xAxis(d[0]) - xAxis(d[1])
          : xAxis(d[1]) - xAxis(d[0])
      )
      .attr("height", yAxis.bandwidth() - (yAxis.bandwidth() / 100) * 40)
      .on("mouseover", function (_, d) {
        tip.style("opacity", 1);
        const c = "white";
        tip.style("color", c);
      })
      .on("mousemove", function (event, d) {
        const parent = (this as Element).parentNode;
        const fuelSourceName = d3.select<any, any>(parent).datum().key;
        const subgroupValue = d.data[fuelSourceName];
        const thisDate = fromUnixTime(d.data.date);
        const records = data
          .flat()
          .filter(
            (r) =>
              r.date.getMonth() === thisDate.getMonth() &&
              r.date.getFullYear() === thisDate.getFullYear()
          );
        const sameValue =
          Array.from(new Set(records.map((r) => r.value)).values()).length ===
          1;
        if (sameValue) {
          tip.html(
            `<h6>${ChartYears(fromUnixTime(d.data.date))}</h6>
            ${records
              .map((r) => {
                return `<div><b>${
                  p.direction === "right"
                    ? p.isEmission
                      ? "Emission"
                      : "Consumption"
                    : "Avoided/Wasted "
                }:</b>${r.value.toFixed(2)}</div>
                <div><b>Site:</b> ${r.siteName}</div>
                <div><b>FuelSource:</b> ${r.fuelSourceName}</div>
              `;
              })
              .join("<hr>")}`
          );
        } else {
          tip.html(`<h6>${ChartYears(fromUnixTime(d.data.date))}</h6>
              <div><b>${
                p.direction === "right"
                  ? p.isEmission
                    ? "Emission"
                    : "Consumption"
                  : "Target "
              }:</b>${subgroupValue.toFixed(2)}</div>
                <div><b>Site:</b> ${d.data.siteName}</div>
                <div><b>FuelSource:</b> ${fuelSourceName}</div>
              `);
        }

        tip.style("left", `${event.pageX}px`).style("top", `${event.pageY}px`);
      })
      .on("mouseleave", function () {
        tip.style("opacity", 0);
      });

    return () => {
      tip?.remove();
      svg?.remove();
    };
  }, [p.dataSet, svgRef.current, p.myRef.current, p.expand]);

  return <svg ref={svgRef} />;
};

interface props {
  loading: boolean;
  expand?: boolean;
  selectedIndex: number;
  energyTargetsRecords: LineChartDataSet[][];
  isEmission?: boolean;
}

export default function DoubleBarChart({
  expand,
  selectedIndex,
  energyTargetsRecords,
  loading,
  isEmission = false,
}: props) {
  const myRef = useRef<any>(null);
  const [width, setWidth] = useState(0);

  // console.log("width => ", width);

  useEffect(() => {
    // Add ResizeObserver to track size changes
    let timeoutId: NodeJS.Timeout;

    try {
      const resizeObserver = new ResizeObserver((entries) => {
        // Clear any existing timeout
        clearTimeout(timeoutId);

        // Set new timeout to update width
        timeoutId = setTimeout(() => {
          for (const entry of entries) {
            setWidth(entry.contentRect.width);
          }
        }, 1); // 250ms delay
      });

      if (myRef.current) {
        resizeObserver.observe(myRef.current);
      }

      return () => {
        clearTimeout(timeoutId);
        resizeObserver.disconnect();
      };
    } catch (error) {
      console.error("Error setting up ResizeObserver:", error);
    }
  }, [myRef.current]); // Empty dependency array since we're using ResizeObserver

  if (loading === true) {
    return (
      <div className="h-100 w-100 d-flex justify-content-between">
        <div
          style={{
            width: "calc(100%)",
          }}
        >
          <div className="d-flex justify-content-center align-items-center h-100">
            <div className="spinner-border" role="status">
              <span className="visually-hidden">Loading...</span>
            </div>
          </div>
        </div>
      </div>
    );
  }

  if (energyTargetsRecords.flat()?.length === 0) {
    return (
      <div className="h-100 w-100 d-flex justify-content-between">
        <div
          style={{
            width: "calc(100%)",
          }}
        >
          <div className="d-flex justify-content-center align-items-center h-100">
            <h4>No data</h4>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="h-100 w-100 d-flex">
      <div
        style={{
          position: "absolute",
          height: "100%",
          width: "100%",
        }}
        ref={myRef}
        id={"stackedSign"}
      >
        <Chart
          dataSet={energyTargetsRecords.map((arr) =>
            arr.map((i) => ({ ...i, value: i.targetEnergy }))
          )}
          selectedIndex={selectedIndex}
          direction="right"
          myRef={myRef}
          expand={expand}
          isEmission={isEmission}
        />
        <Chart
          dataSet={energyTargetsRecords.map((arr) =>
            arr.map((i) => ({ ...i, value: i.consumption }))
          )}
          selectedIndex={selectedIndex}
          direction="left"
          myRef={myRef}
          expand={expand}
          isEmission={isEmission}
        />
      </div>
    </div>
  );
}
