import { ResponsiveContainer, BarChart, Bar } from "recharts";
import { getFullMonthName } from "../../../Helper/DateFormat";
import D3DisplayDiff from "./D3DisplayDiff";

const data = [
  {
    date: new Date(2020, 0, 1),
    value: 97,
  },
  {
    date: new Date(2020, 1, 1),
    value: 80,
  },
  {
    date: new Date(2020, 2, 1),
    value: 65,
  },
  {
    date: new Date(2020, 3, 1),
    value: 20,
  },
];

export const toThermometerData =
  <T extends unknown>(p: {
    date: (i: T) => Date;
    value: (i: T) => number;
    diff: (i: T) => number;
  }) =>
  (i: T) => {
    return {
      date: p.date(i),
      value: p.value(i),
      diff: p.diff(i),
    };
  };

interface props {
  expand?: boolean;
  data: Array<{ date: Date; value: number; diff: number }>;
  loading: boolean;
}

const CustomBar = ({ y, height, payload, columnAmount, expand }: any) => {
  let fullLength = expand 
    ? y + height - 80
    : y + height - 60;
  
  let value = Math.abs(payload.diff);

  const a = 100;
  let ratio = payload.diff === 0 
    ? fullLength 
    : payload.index === 3 
      ? 40 
      : ((a - value) * fullLength) / 100;

  let Color = payload.diff === 0 ? "url(#greenGradient)" : "white";
  
  let xCircle = Math.sqrt(100 - (10 - ratio) ** 2);

  let percent0 = ((100 - 5) * fullLength) / 100 + 30;
  let percent20 = ((100 - 20) * fullLength) / 100 + 30;
  let percent40 = ((100 - 40) * fullLength) / 100 + 30;
  let percent60 = ((100 - 60) * fullLength) / 100 + 30;
  let percent80 = ((100 - 80) * fullLength) / 100 + 30;
  let percent100 = ((100 - 100) * fullLength) / 100 + 30;

  const width = 110;
  let index = payload.index;

  const defaultMargin = width * 1.3;

  const x = defaultMargin * index;

  const fillColor = payload.index === 3 
    ? "url(#greenGradient)"
    : payload.diff > 0
      ? "url(#greenGradient)"
      : payload.diff < 0
        ? "url(#redGradient)"
        : "url(#greenGradient)";

  return (
    <>
      <g>
        <text x={x + width / 3} y={10} textAnchor="middle" fill="black">
          {getFullMonthName(payload.date)}
        </text>
        {/* <defs> */}
        <D3DisplayDiff
          width={x + width}
          x={x + width / 3}
          difference={payload.diff}
        />
        {/* </defs> */}

        <rect
          width={width}
          height="93%"
          x={x}
          y={13}
          rx={30}
          fill={index === 0 ? "#4D505C" : "#E8E8E8"}
        />

        <rect
          width={20}
          height={fullLength}
          x={x + width / 2 - 10}
          y={20}
          fill={fillColor}
          stroke="#00000000"
          rx={10}
        />

        {value >= 6 || payload.index === 3 ? (
          <path
            d={`M${x + width / 2 - 10} 30 a 10,10 0 0 1 20,0 v ${payload.index === 3 ? 40 : ratio} h-20`}
            fill={Color}
            stroke="#00000000"
          />
        ) : (
          <path
            d={`M${x + width / 2 - 10} 30 a 10,10 0 0 1 20,0 v ${280} h-20`}
            fill={Color}
            stroke="#00000000"
          />
        )}

        <text
          x={x + width / 2 + 15}
          y={40}
          textAnchor="start"
          fill={index === 0 ? "white" : "#8E8E8E"}
        >
          100%
        </text>

        <line
          x1={x + width / 2 - 10}
          y1={percent20}
          x2={x + width / 2 + 30}
          y2={percent20}
          fill="white"
          stroke="white"
        />
        <text
          x={x + width / 2 + 15}
          y={percent20+10}
          textAnchor="start"
          fill={index === 0 ? "white" : "#8E8E8E"}
        >
          20%
        </text>

        <line
          x1={x + width / 2 - 10}
          y1={percent40}
          x2={x + width / 2 + 30}
          y2={percent40}
          fill="white"
          stroke="white"
        />
        <text
          x={x + width / 2 + 15}
          y={percent40 + 20}
          textAnchor="start"
          fill={index === 0 ? "white" : "#8E8E8E"}
        >
          40%
        </text>
        <line
          x1={x + width / 2 - 10}
          y1={percent60}
          x2={x + width / 2 + 30}
          y2={percent60}
          fill="white"
          stroke="white"
        />
        <text
          x={x + width / 2 + 15}
          y={percent60 + 20}
          textAnchor="start"
          fill={index === 0 ? "white" : "#8E8E8E"}
        >
          60%
        </text>
        <line
          x1={x + width / 2 - 10}
          y1={percent80}
          x2={x + width / 2 + 30}
          y2={percent80}
          fill="white"
          stroke="white"
        />
        <text
          x={x + width / 2 + 15}
          y={percent80 + 20}
          textAnchor="start"
          fill={index === 0 ? "white" : "#8E8E8E"}
        >
          80%
        </text>

        <line
          x1={x + width / 2 - 10}
          y1={percent0}
          x2={x + width / 2 + 30}
          y2={percent0}
          fill="white"
          stroke="white"
        />
        <text
          x={x + width / 2 + 15}
          y={percent0}
          textAnchor="start"
          fill={index === 0 ? "white" : "#8E8E8E"}
        >
          0%
        </text>

        <path
          d={`M${x + width / 2},${fullLength
            +43} a 16, 16 0 1 1 0,-32 v-18`}
          fill={fillColor}
        />
        <path
          d={`M${x + width / 2},${fullLength +11} a 16, 16 0 1 1 0,32 v-40`}
          fill={fillColor}
        />
      </g>
    </>
  );
};

export default function Thermometer({ expand, data, loading }: props) {
  const transformedData = data.map((x, i) => {
    return {
      index: i,
      ...x,
    };
  });

  if (loading) {
    return (
      <div className="d-flex justify-content-center align-items-center h-100">
        <div className="spinner-border" role="status">
          <span className="visually-hidden">Loading...</span>
        </div>
      </div>
    );
  }

  if (data.length === 0) {
    return <h5 className="text-center pt-5">No data</h5>;
  }

  return (
    <ResponsiveContainer width="100%" height="96%">
      <BarChart data={transformedData} id={"carbonGauge"}>
        <defs>
          <linearGradient id="greenGradient" x1="0" y1="0" x2="0" y2="1">
            <stop offset="0%" stopColor="#4C8A2D" stopOpacity={1} />
            <stop offset="50%" stopColor="#8AB41E" stopOpacity={1} />
            <stop offset="100%" stopColor="#C6E9A6" stopOpacity={1} />
          </linearGradient>
          <linearGradient id="redGradient" x1="0" y1="0" x2="0" y2="1">
            <stop offset="0%" stopColor="#B22222" stopOpacity={1} />
            <stop offset="50%" stopColor="#E97871" stopOpacity={1} />
            <stop offset="100%" stopColor="#F5A623" stopOpacity={1} />
          </linearGradient>
        </defs>

        <Bar
          type="monotone"
          dataKey="value"
          fill="#4989C6"
          shape={<CustomBar columnAmount={data.length} expand={expand} />}
        />
      </BarChart>
    </ResponsiveContainer>
  );
}
