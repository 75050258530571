import { useEffect, useMemo, useState } from "react";
import Layout from "../Layout";
import Base from "../SideMenu/Base";
import style from "../analytics.module.css";
import ConsumptionPanel from "./ConsumptionPanel";
import { ActionCreators, State } from "../../State";
import { useDispatch, useSelector } from "react-redux";
import TargetConsumptionPanel from "./TargetConsumptionPanel";
import FuelPanel from "./FuelPanel";
import CostPanel from "./CostPanel";
import UtilityLeageState from "./LeageState";
import SideMenuState from "./LeageState";
import { bindActionCreators } from "redux";
import useQueryManager from "../../Hooks/useQueryManager";
import { increasedPercentage } from "../../Helper/increasedPercentage";
import { log } from "console";

export default function UtilityLeague() {
  const urlQuery = useQueryManager();
  const siteParam = useMemo(() => urlQuery.get("site"), [urlQuery.get("site")]);
  const fuelParam = useMemo(() => urlQuery.get("fuel"), [urlQuery.get("fuel")]);
  const year = useSelector((state: State) => state.year);
  const site = useSelector((state: State) => state.site);
  const month = useSelector((state: State) => state.month);
  const sideMenuState = SideMenuState();
  const dispatch = useDispatch();
  const { ChangeSite, ChangeFuelSource } = bindActionCreators(
    ActionCreators,
    dispatch
  );
  // ... existing code ...
  // ... existing code ...
  const [DataArray, setDataArray] = useState<any[]>();
  const [extracted, setExtracted] = useState<string>("");
  // ... existing code ...
  // ... existing code ...

  const pageState = UtilityLeageState();

  useEffect(() => {
    pageState.fetchSites();
  }, []);

  useEffect(() => {
    if (!siteParam && !site && sideMenuState?.sites?.length > 0) {
      ChangeSite(sideMenuState.sites[0]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sideMenuState.sites, site]);

  useEffect(() => {
    const thereIsFuelSources = sideMenuState.fuelSources.length > 0;
    const thereIsConsumptions = sideMenuState.consumptions.flat().length > 0;
    if (!fuelParam && thereIsFuelSources && thereIsConsumptions) {
      const firstConsumption = sideMenuState.consumptions.flat()[0];
      const item = sideMenuState.fuelSources.find(
        (f) => f.id === firstConsumption.fuelSourceId
      );
      if (item) {
        ChangeFuelSource({ value: item.id, label: item.source });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sideMenuState.fuelSources, sideMenuState.isLoading]);

  useEffect(() => {
    const fetchData = async () => {
      if (year && site) {
        const res: any = await pageState.triggerStatisticsSearch({
          year,
          site,
        });
        console.log("Result", res);
        setDataArray(res);
      }
    };
    pageState.triggerStatisticsSearchPreviousYear({
      year,
      site,
    });
    fetchData();
  }, [year, site]);

  // useEffect(() => {
  //   const consumption = DataArray?.flat() ?? [];
  //   const extractedSet = new Set(consumption.map((item) => item.usedIn));
  //   const extractedArray = Array.from(extractedSet);
  //   const extractedString = extractedArray.join(", "); // Join Array to create a string
  //   setExtracted(extractedString);
  // }, [sideMenuState.consumptions]);
  return (
    <Layout rightSideBar={<Base data={DataArray} />}>
      <div className="flex-fill d-flex flex-column">
        <h4 className={`${style.sectionHeader} mt-4 text-center`}>
          Consumption and Cost
        </h4>
        <div className="flex-fill mx-3 mb-4 position-relative">
          <ConsumptionPanel
            loading={pageState.isLoading}
            records={pageState.consumptions}
          />
          <TargetConsumptionPanel />
          <CostPanel
            consumptions={sideMenuState.consumptions}
            selectedYear={sideMenuState.selectedYear.value}
            selectedMonthDaysAmount={sideMenuState.selectedMonthDaysAmount || 0}
            currentRecord={sideMenuState.currentRecord}
            increasedPercentage={increasedPercentage(
              sideMenuState.consumptions,
              month.value
            )}
            currentMonthCost={sideMenuState.currentRecord?.cost}
            averageMonthCost={sideMenuState.currentRecord?.averageCost}
            diffCostPrevMonth={
              sideMenuState.currentRecord?.cost -
              sideMenuState.previousRecord?.cost
            }
            tooltipTag="Cost"
          />
          <FuelPanel loading={false} />
        </div>
      </div>
    </Layout>
  );
}
